import React from 'react';
import './About.css';

const About = (props) => {
  return (
    <div className="About">
      <p onClick={props.revealGallery} className="clickable">&larr;</p>
      <br />
      <p>Nicole Clare Fraser (b. 1989) is an image maker and artist based in Cape Town. Her making is photographic in practice, looking at built structures, interior spaces and objects suggestive of human presence, absence, loneliness and impressions of tenderness.</p>
      <p>During her high school years, Nicole began working in the photographic darkroom. Here, she developed her strong regard and sensitivity toward the medium and the process of printing silver gelatin hand prints. After school, she continued to pursue her affinity for making images and studied further at the Michaelis School of Fine Art.</p>
      <p>Alongside her personal practice, Nicole lectures in photography, with a particular focus on darkroom techniques and printing. She has taught photography at various secondary and tertiary institutions in Cape Town. Furthering her skill in the medium, she has completed darkroom photography and hand printing workshops with the highly regarded and master black and white printer, Dennis Da Silva, in Johannesburg.</p>
      <p>Nicole holds an MFA (2022) and BAFA (2012) from the Michaelis School of Fine Art, Cape Town. Her solo shows include: Making Space: Traces of Absence, Stillness and the In-Between in Public Spaces (2021) which was presented in the fulfillment of her MFA and publicly re-exhibited at Daor Contemporary in 2023, untitled (façade) (2020), a quiet place | a collection of hand-printed silver gelatin prints (2018) and Garrisons (2012).</p>
      <br />
      <div className="break"></div>
      <h4>Solo exhibitions</h4>
      <table>
        <tr>
          <td className="year"><p>2023</p></td>
          <td><p><i>Making Space: Photographic Traces of Absence, Stillness and the In-Between in Public Spaces</i>, Daor Contemporary, Cape Town</p></td>
        </tr>
        <tr>
          <td className="year"><p>2021</p></td>
          <td><p><i>Making Space: Photographic Traces of Absence, Stillness and the In-Between in Public Spaces</i>, An exhibition in fulfillment of an MFA at the Michaelis School of Fine Art</p></td>
        </tr>
        <tr>
          <td className="year"><p>2020</p></td>
          <td><p><i>untitled (façade)</i>, 99Loop Gallery, Cape Town</p></td>
        </tr>
        <tr>
          <td className="year"><p>2018</p></td>
          <td><p><i>a quiet place | a collection of hand-printed silver gelatin prints</i>, ORMS Cape Town School of Photography, Cape Town</p></td>
        </tr>
        <tr>
          <td className="year"><p>2012</p></td>
          <td><p><i>Garrisons</i>, Michaelis Graduate Exhibition, University of Cape Town</p></td>
        </tr>
      </table>
      <br />
      <h4>Selected group exhibitions</h4>
      <table>
        <tr>
          <td className="year"><p>2023</p></td>
          <td>
            <p><i>Pandemic | Praxis | Pedagogy</i>, Michaelis School of Fine Art, Cape Town</p>
          </td>
        </tr>
        <tr>
          <td className="year"><p>2022</p></td>
          <td>
            <p><i>Ebb</i>, Daor Contemporary, Cape Town</p>
            <p><i>girls girls girls</i>, Daor Contemporary, Cape Town</p>
            <p><i>Days of being wild...</i>, Cavalli Estate, Somerset West</p>
          </td>
        </tr>
        <tr>
          <td className="year"><p>2021</p></td>
          <td><p><i>Making and Interpreting art in 2021: A Conference and Exhibition for Honours and Masters' Students in Visual Art</i>, UJ</p></td>
        </tr>
        <tr>
          <td className="year"><p>2020</p></td>
          <td>
            <p><i>A Hazy Shade of Winter, Salon91</i>, Cape Town</p>
            <p>Investec Cape Town Art Fair with Salon91, Cape Town</p>
            <p><i>Nostalgia</i>, DAOR Contemporary, Cape Town</p>
          </td>
        </tr>
        <tr>
          <td className="year"><p>2019</p></td>
          <td>
            <p><i>Regarding Winter</i>, Salon91, Cape Town</p>
            <p><i>Half Tone</i>, ORMS Cape Town School of Photography, Cape Town</p>
            <p><i>-nano1.3</i>, a group exhibition at Barnard, Cape Town</p>
            <p><i>The Distance From Afar</i>, Salon91 in Collaboration with Glen Carlou</p>
            <p><i>About Photography, Summer Salon</i>, PhCentre, Cape Town</p>
          </td>
        </tr>
        <tr>
          <td className="year"><p>2018</p></td>
          <td>
            <p><i>Folklore, end-of-year group show</i>, Salon91, Cape Town</p>
            <p>Staff Exhibition at the Peter Clark Art Centre, Cape Town</p>
            <p><i>-nano 1.2</i>, a group exhibition at Barnard, Cape Town</p>
            <p><i>Throwing Shapes, a group show of contemporary photography</i>, Smith Studio, Cape Town</p>
            <p>ORMS Cape Town School of Photography Staff Exhibition, Cape Town</p>
          </td>
        </tr>
        <tr>
          <td className="year"><p>2017</p></td>
          <td>
            <p><i>By Way of Hand</i>. ORMS Cape Town School of Photography, Cape Town</p>
            <p>ORMS Cape Town School of Photography Staff Exhibition, Cape Town</p>
          </td>
        </tr>
        <tr>
          <td className="year"><p>2013</p></td>
          <td>
            <p><i>Works of Art: New Engineering Building</i>, University of Cape Town, Permanent Collection, Cape Town</p>
            <p><i>Top 100 Finalists</i>, Absa L'Atelier Exhibition, Cape Town and Johannesburg</p>
            <p><i>Greatest Hits 2012: If the halls could talk</i>. 2012 Graduate Student group exhibition at the AVA Gallery, Cape Town</p>
          </td>
        </tr>
        <tr>
          <td className="year"><p>2012</p></td>
          <td><p><i>Garrisons</i>, Michaelis Graduate Exhibition, University of Cape Town</p></td>
        </tr>
      </table>
      <br />
      <div className="break"></div>
      <p>nicoleclarefraser@gmail.com</p>
      <p>+27 (0) 83 287 1870</p>
    </div>
  );
};

export default About;
