import aqp1 from './AQuietPlace/1.jpg';
import aqp2 from './AQuietPlace/2.jpg';
import aqp3 from './AQuietPlace/3.jpg';
import aqp4 from './AQuietPlace/4.jpg';
import aqp5 from './AQuietPlace/5.jpg';
import aqp6 from './AQuietPlace/6.jpg';
import aqp7 from './AQuietPlace/7.jpg';
import aqp8 from './AQuietPlace/8.jpg';
import aqp9 from './AQuietPlace/9.jpg';
import aqp10 from './AQuietPlace/10.jpg';
import aqp11 from './AQuietPlace/11.jpg';
import aqp12 from './AQuietPlace/12.jpg';
import aqp13 from './AQuietPlace/13.jpg';
import aqp14 from './AQuietPlace/14.jpg';
import aqp15 from './AQuietPlace/15.jpg';
import aqp16 from './AQuietPlace/16.jpg';
import aqp17 from './AQuietPlace/17.jpg';
import aqp18 from './AQuietPlace/18.jpg';
import aqp19 from './AQuietPlace/19.jpg';
import aqp20 from './AQuietPlace/20.jpg';

import uf1 from './Untitled/1.jpg';
import uf2 from './Untitled/2.jpg';
import uf3 from './Untitled/3.jpg';
import uf4 from './Untitled/4.jpg';
import uf5 from './Untitled/5.jpg';
import uf6 from './Untitled/6.jpg';
import uf7 from './Untitled/7.jpg';
import uf8 from './Untitled/8.jpg';
import uf9 from './Untitled/9.jpg';
import uf10 from './Untitled/10.jpg';
import uf11 from './Untitled/11.jpg';
import uf12 from './Untitled/12.jpg';
import uf13 from './Untitled/13.jpg';
import uf14 from './Untitled/14.jpg';
import uf15 from './Untitled/15.jpg';
import uf16 from './Untitled/16.jpg';

import ms1 from './MakingSpace/1.jpg';
import ms2 from './MakingSpace/2.jpg';
import ms3 from './MakingSpace/3.jpg';
import ms4 from './MakingSpace/4.jpg';
import ms5 from './MakingSpace/5.jpg';
import ms6 from './MakingSpace/6.jpg';
import ms7 from './MakingSpace/7.jpg';
import ms8 from './MakingSpace/8.jpg';
import ms9 from './MakingSpace/9.jpg';
import ms10 from './MakingSpace/10.jpg';
import ms11 from './MakingSpace/11.jpg';
import ms12 from './MakingSpace/12.jpg';
import ms13 from './MakingSpace/13.jpg';
import ms14 from './MakingSpace/14.jpg';
import ms15 from './MakingSpace/15.jpg';
import ms16 from './MakingSpace/16.jpg';
import ms17 from './MakingSpace/17.jpg';
import ms18 from './MakingSpace/18.jpg';
import ms19 from './MakingSpace/19.jpg';
import ms20 from './MakingSpace/20.jpg';
import ms21 from './MakingSpace/21.jpg';
import ms22 from './MakingSpace/22.jpg';
import ms23 from './MakingSpace/23.jpg';
import ms24 from './MakingSpace/24.jpg';
import ms25 from './MakingSpace/25.jpg';
import ms26 from './MakingSpace/26.jpg';
import ms27 from './MakingSpace/27.jpg';
import ms28 from './MakingSpace/28.jpg';
import ms29 from './MakingSpace/29.jpg';
import ms30 from './MakingSpace/30.jpg';
import ms31 from './MakingSpace/31.jpg';
import ms32 from './MakingSpace/32.jpg';
import ms33 from './MakingSpace/33.jpg';
import ms34 from './MakingSpace/34.jpg';
import ms35 from './MakingSpace/35.jpg';
import ms36 from './MakingSpace/36.jpg';
import ms37 from './MakingSpace/37.jpg';
import ms38 from './MakingSpace/38.jpg';
import ms39 from './MakingSpace/39.jpg';
import ms40 from './MakingSpace/40.jpg';
import ms41 from './MakingSpace/41.jpg';
import ms42 from './MakingSpace/42.jpg';
import ms43 from './MakingSpace/43.jpg';
import ms44 from './MakingSpace/Installation_12.jpg';
import ms45 from './MakingSpace/Installation_01.jpg';
import ms46 from './MakingSpace/Installation_02.jpg';
import ms47 from './MakingSpace/Installation_03.jpg';
import msVideo from './MakingSpace/NCF_Book_Pagethrough.mp4';
import msVideoPoster from './MakingSpace/NCF_Book_Pagethrough.jpg';

const aqpImage1 = {
  image: aqp1,
  title: "Untitled 1 (Darkroom), 2016",
  method: "Silver gelatin hand-print on fibre based paper",
  dimension: "460mm x 405mm"
}

const aqpImage2 = {
  image: aqp2,
  title: "Untitled 2 (Darkroom), 2017",
  method: "Silver gelatin hand-print on fibre based paper",
  dimension: "460mm x 405mm"
}

const aqpImage3 = {
  image: aqp3,
  title: "Untitled 3 (Darkroom), 2016",
  method: "Silver gelatin hand-print on fibre based paper",
  dimension: "460mm x 405mm"
}

const aqpImage4 = {
  image: aqp4,
  title: "Untitled 4 (Darkroom), 2017",
  method: "Silver gelatin hand-print on fibre based paper",
  dimension: "460mm x 405mm"
}

const aqpImage5 = {
  image: aqp5,
  title: "Untitled 5 (Darkroom), 2017",
  method: "Silver gelatin hand-print on fibre based paper",
  dimension: "460mm x 405mm"
}

const aqpImage6 = {
  image: aqp6,
  title: "Untitled 6 (Darkroom), 2016",
  method: "Silver gelatin hand-print on fibre based paper",
  dimension: "460mm x 405mm"
}

const aqpImage7 = {
  image: aqp7,
  title: "Untitled 1 (Trees and Concrete Wall), 2017",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "250 mm x 195 mm"
}

const aqpImage8 = {
  image: aqp8,
  title: "Untitled 2 (Tramway), 2017",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "250 mm x 195 mm"
}

const aqpImage9 = {
  image: aqp9,
  title: "Untitled 3 (Promenade), 2017",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "250 mm x 195 mm"
}

const aqpImage10 = {
  image: aqp10,
  title: "Untitled 4 (Trolleys), 2016",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "250 mm x 195 mm"
}

const aqpImage11 = {
  image: aqp11,
  title: "Untitled 5 (Chair), 2017",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "250 mm x 195 mm"
}

const aqpImage12 = {
  image: aqp12,
  title: "Untitled 6 (Chair and Crates), 2017",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "250 mm x 195 mm"
}

const aqpImage13 = {
  image: aqp13,
  title: "Untitled 7 (Cacti), 2017",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "250 mm x 195 mm"
}

const aqpImage14 = {
  image: aqp14,
  title: "Untitled 8 (Table and Chairs), 2017",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "250 mm x 195 mm"
}

const aqpImage15 = {
  image: aqp15,
  title: "Untitled 9 (Deck Chair), 2017",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "250 mm x 195 mm"
}

const aqpImage16 = {
  image: aqp16,
  title: "Untitled 10 (Two Deck Chairs), 2017",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "250 mm x 195 mm"
}

const aqpImage17 = {
  image: aqp17,
  title: "Untitled 11 (Hanging Chair), 2017",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "130 mm x 200 mm"
}

const aqpImage18 = {
  image: aqp18,
  title: "Untitled 12 (Line of Trees), 2017",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "130 mm x 200 mm"
}

const aqpImage19 = {
  image: aqp19,
  title: "Untitled 13 (Stairs with Door), 2016",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "130 mm x 200 mm"
}

const aqpImage20 = {
  image: aqp20,
  title: "Untitled 14 (Walls), 2016",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "130 mm x 200 mm"
}

const aqpImages = [
  aqpImage1, 
  aqpImage2, 
  aqpImage3, 
  aqpImage4, 
  aqpImage5, 
  aqpImage6, 
  aqpImage7, 
  aqpImage8, 
  aqpImage9, 
  aqpImage10, 
  aqpImage11, 
  aqpImage12, 
  aqpImage13, 
  aqpImage14, 
  aqpImage15, 
  aqpImage16, 
  aqpImage17, 
  aqpImage18, 
  aqpImage19, 
  aqpImage20
];

const aqpTitle = "a quiet place";
const aqpSubtitle1 = "an exhibition of hand-printed silver gelatin prints";
const aqpSubtitle2 = "by Nicole Clare Fraser";

const aqpParagraph1 = "Nicole Clare Fraser's exhibition, a quiet place, offers us refuge and stillness within the chaos of everyday life. The collecting of spaces, ordered and composed through the lens, find form within the slow and measured process of working in the Darkroom. The body of work is captured on black and white 35mm and medium format film and hand-printed in the darkroom by Nicole.";
const aqpParagraph2 = "Images of darkrooms suggest a nostalgia for traditional image-making practices and the past, a celebration of something disappearing, an attempt to memorialise a technique that is dwindling. The images exist as a framework to share the physical space within which the work is created. These darkrooms, where time and light dictate, enable Nicole to methodically and meticulously create tactile representations of what she captures in her day-to-day experiences.";
const aqpBody = [aqpParagraph1, aqpParagraph2];

const aqp = {
  images: aqpImages, 
  title: aqpTitle,
  subtitle1: aqpSubtitle1,
  subtitle2: aqpSubtitle2,
  body: aqpBody
}

const ufImage1 = {
  image: uf1,
  title: "Untitled, 2019",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "203mm x 254mm"
}

const ufImage2 = {
  image: uf2,
  title: "Untitled, 2019",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "254mm x 203mm"
}

const ufImage3 = {
  image: uf3,
  title: "Untitled, 2019",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "254mm x 203mm"
}

const ufImage4 = {
  image: uf4,
  title: "Untitled, 2019",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "203mm x 254mm"
}

const ufImage5 = {
  image: uf5,
  title: "Untitled, 2019",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "203mm x 254mm"
}

const ufImage6 = {
  image: uf6,
  title: "Untitled, 2019",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "203mm x 254mm"
}

const ufImage7 = {
  image: uf7,
  title: "Untitled, 2019",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "203mm x 254mm"
}

const ufImage8 = {
  image: uf8,
  title: "Untitled, 2019",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "203mm x 254mm"
}

const ufImage9 = {
  image: uf9,
  title: "Untitled, 2019",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "203mm x 254mm"
}

const ufImage10 = {
  image: uf10,
  title: "Untitled, 2019",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "203mm x 254mm"
}

const ufImage11 = {
  image: uf11,
  title: "Untitled, 2018",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "203mm x 254mm"
}

const ufImage12 = {
  image: uf12,
  title: "Untitled, 2018",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "203mm x 254mm"
}

const ufImage13 = {
  image: uf13,
  title: "Untitled, 2018",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "203mm x 254mm"
}

const ufImage14 = {
  image: uf14,
  title: "Untitled, 2019",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "203mm x 254mm"
}

const ufImage15 = {
  image: uf15,
  title: "Untitled, 2019",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "203mm x 254mm"
}

const ufImage16 = {
  image: uf16,
  title: "Untitled, 2019",
  method: "Silver gelatin hand-print on cotton rag",
  dimension: "203mm x 254mm"
}

const ufImages = [
  ufImage1,
  ufImage2,
  ufImage3,
  ufImage4,
  ufImage5,
  ufImage6,
  ufImage7,
  ufImage8,
  ufImage9,
  ufImage10,
  ufImage11,
  ufImage12,
  ufImage13,
  ufImage14,
  ufImage15,
  ufImage16
]

const ufTitle = "untitled (façade)";
const ufSubtitle1 = "an exhibition of black and white silver gelatin hand-prints on 100% cotton rag";
const ufSubtitle2 = "by Nicole Clare Fraser";

const ufParagraph1 = "This body of work is an exploration of space and objects. The spaces captured are everyday places Nicole has encountered and recorded with a critical eye, looking closely at compositional elements and a soft sense of tonality. Nicole's photography explores a sense of quiet, solitude and the mundane in a poetically atmospheric manner. The images are captured on 35mm black and white film and are hand-printed in the darkroom by the artist.";
const ufParagraph2 = "";
const ufBody = [ufParagraph1, ufParagraph2];

const uf = {
  images: ufImages, 
  title: ufTitle,
  subtitle1: ufSubtitle1,
  subtitle2: ufSubtitle2,
  body: ufBody
}

const msImage1 = {
  image: ms1,
  title: "Factory Wall Exterior",
  year: "2019",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 203mm x 254mm",
  framed: "Framed 330mm x 430mm",
  edition: "Edition of 3 + AP"
}

const msImage2 = {
  image: ms2,
  title: "Building Façade",
  year: "2021",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250mm x 200mm",
  framed: "Framed 300mm x 280mm",
  edition: "Edition of 3 + AP"
}

const msImage3 = {
  image: ms3,
  title: "Building Exterior",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250mm x 200mm",
  framed: "Framed 300mm x 280mm",
  edition: "Edition of 3 + AP"
}

const msImage4 = {
  image: ms4,
  title: "Doorway with Surveillance Camera",
  year: "2021",
  method: "Archival ink on Innova 300gsm cotton rag paper",
  paperSize: "Paper size 1140 x 915 mm",
  framed: "Framed 1090 x 930 mm",
  edition: "Edition of 3 + AP"
}

const msImage5 = {
  image: ms5,
  title: "Stadium I",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250mm x 200mm",
  framed: "Framed 300mm x 280mm",
  edition: "Edition of 3 + AP"
}

const msImage6 = {
  image: ms6,
  title: "Stadium II",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250mm x 200mm",
  framed: "Framed 300mm x 280mm",
  edition: "Edition of 3 + AP"
}

const msImage7 = {
  image: ms7,
  title: "Two Chairs",
  year: "2021",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250mm x 200mm",
  framed: "Framed 300mm x 280mm",
  edition: "Edition of 3 + AP"
}

const msImage8 = {
  image: ms8,
  title: "Changing Room",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 400 x 300 mm ",
  framed: "Framed 480 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage9 = {
  image: ms9,
  title: "Underground Parking",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 400 x 300 mm ",
  framed: "Framed 480 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage10 = {
  image: ms10,
  title: "Garage Door",
  year: "2020",
  method: "Archival ink on Innova 300gsm cotton rag paper",
  paperSize: "Paper size 1140 x 915 mm",
  framed: "Framed 1090 x 930 mm",
  edition: "Edition of 3 + AP"
}

const msImage11 = {
  image: ms11,
  title: "Airport Trolleys I",
  year: "2021",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250mm x 200mm",
  framed: "Framed 300mm x 280mm",
  edition: "Edition of 3 + AP"
}

const msImage12 = {
  image: ms12,
  title: "Airport Trolleys II",
  year: "2021",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250mm x 200mm",
  framed: "Framed 300mm x 280mm",
  edition: "Edition of 3 + AP"
}

const msImage13 = {
  image: ms13,
  title: "Squash Court II",
  year: "2021",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250mm x 200mm",
  framed: "Framed 300mm x 280mm",
  edition: "Edition of 3 + AP"
}

const msImage14 = {
  image: ms14,
  title: "Empty Pool",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250mm x 200mm",
  framed: "Framed 300mm x 280mm",
  edition: "Edition of 3 + AP"
}

const msImage15 = {
  image: ms15,
  title: "Sports Centre",
  year: "2021",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250mm x 200mm",
  framed: "Framed 300mm x 280mm",
  edition: "Edition of 3 + AP"
}

const msImage16 = {
  image: ms16,
  title: "Line of Seating",
  year: "2021",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250mm x 200mm",
  framed: "Framed 300mm x 280mm",
  edition: "Edition of 3 + AP"
}

const msImage17 = {
  image: ms17,
  title: "Campus Benches",
  year: "2021",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250mm x 200mm",
  framed: "Framed 300mm x 280mm",
  edition: "Edition of 3 + AP"
}

const msImage18 = {
  image: ms18,
  title: "High School Locker Room",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250mm x 200mm",
  framed: "Framed 300mm x 280mm",
  edition: "Edition of 3 + AP"
}

const msImage19 = {
  image: ms19,
  title: "Racecourse Staircase",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250mm x 200mm",
  framed: "Framed 300mm x 280mm",
  edition: "Edition of 3 + AP"
}

const msImage20 = {
  image: ms20,
  title: "Parking Lot",
  year: "2020",
  method: "Archival ink on Innova 300gsm cotton rag paper",
  paperSize: "Paper size 1140 x 915 mm",
  framed: "Framed 1090 x 930 mm",
  edition: "Edition of 3 + AP"
}

const msImage21 = {
  image: ms21,
  title: "Pillars and Chairs",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 400 x 300 mm ",
  framed: "Framed 480 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage22 = {
  image: ms22,
  title: "Racecourse Hall and TV Screens",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 400 x 300 mm ",
  framed: "Framed 480 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage23 = {
  image: ms23,
  title: "Factory",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 300 x 400 mm ",
  framed: "Framed 380 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage24 = {
  image: ms24,
  title: "Shooting Range I",
  year: "2020",
  method: "Archival ink on Innova 300gsm cotton rag paper",
  paperSize: "Paper size 1140 x 915 mm",
  framed: "Framed 1090 x 930 mm",
  edition: "Edition of 3 + AP"
}

const msImage25 = {
  image: ms25,
  title: "Squash Court I",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 400 x 300 mm ",
  framed: "Framed 480 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage26 = {
  image: ms26,
  title: "Stadium",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 400 x 300 mm ",
  framed: "Framed 480 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage27 = {
  image: ms27,
  title: "Civic Centre Dining Hall",
  year: "2021",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 400 x 300 mm ",
  framed: "Framed 480 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage28 = {
  image: ms28,
  title: "Gallows Hill Traffic Department II",
  year: "2021",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250 x 200 mm ",
  framed: "Framed 300 x 280 mm",
  edition: "Edition of 3 + AP"
}

const msImage29 = {
  image: ms29,
  title: "Shooting Range II",
  year: "2021",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250 x 200 mm ",
  framed: "Framed 300 x 280 mm",
  edition: "Edition of 3 + AP"
}

const msImage30 = {
  image: ms30,
  title: "Baxter Theatre",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 400 x 300 mm ",
  framed: "Framed 480 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage31 = {
  image: ms31,
  title: "The Little Theatre",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 400 x 300 mm ",
  framed: "Framed 480 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage32 = {
  image: ms32,
  title: "Gallows Hill Traffic Department I",
  year: "2021",
  method: "Archival ink on Innova 300gsm cotton rag paper",
  paperSize: "Paper size 1140 x 915 mm",
  framed: "Framed 1090 x 930 mm",
  edition: "Edition of 3 + AP"
}

const msImage33 = {
  image: ms33,
  title: "Fertility Clinic",
  year: "2021",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 400 x 300 mm ",
  framed: "Framed 480 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage34 = {
  image: ms34,
  title: "Swimming Pool",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 400 x 300 mm ",
  framed: "Framed 480 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage35 = {
  image: ms35,
  title: "Stacked Plastic Chairs",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 300 x 400 mm ",
  framed: "Framed 380 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage36 = {
  image: ms36,
  title: "Gallows Hill Traffic Department III",
  year: "2021",
  method: "Archival ink on Innova 300gsm cotton rag paper",
  paperSize: "Paper size 1140 x 915 mm",
  framed: "Framed 1090 x 930 mm",
  edition: "Edition of 3 + AP"
}

const msImage37 = {
  image: ms37,
  title: "Ladder",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 300 x 400 mm ",
  framed: "Framed 380 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage38 = {
  image: ms38,
  title: "Pool Ladder",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 300 x 400 mm ",
  framed: "Framed 380 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage39 = {
  image: ms39,
  title: "Dressing Room",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 400 x 300 mm ",
  framed: "Framed 480 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage40 = {
  image: ms40,
  title: "Fertility Clinic Bed",
  year: "2021",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250 x 200 mm ",
  framed: "Framed 300 x 280 mm",
  edition: "Edition of 3 + AP"
}

const msImage41 = {
  image: ms41,
  title: "Reclining Beds",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 400 x 300 mm ",
  framed: "Framed 480 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage42 = {
  image: ms42,
  title: "Apartment Block",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 250 x 200 mm ",
  framed: "Framed 300 x 280 mm",
  edition: "Edition of 3 + AP"
}

const msImage43 = {
  image: ms43,
  title: "Home",
  year: "2020",
  method: "Silver gelatin handprint",
  paperSize: "Paper size 300 x 400 mm ",
  framed: "Framed 380 x 440 mm",
  edition: "Edition of 3 + AP"
}

const msImage44 = {
  image: ms44,
  title: "Escalator",
  year: "2021",
  method: "Video projection",
  paperSize: "Size variable",
}

const msImage45 = {
  image: ms45,
  title: "Installation views of Making Space",
  year: "2021",
}

const msImage46 = {
  image: ms46,
  title: "Installation views of Making Space",
  year: "2021",
}

const msImage47 = {
  image: ms47,
  title: "Installation views of Making Space",
  year: "2021",
}

const msVideo1 = {
  video: msVideo,
  title: "Book pagethrough of Making Space",
  method: "(Click image to play)",
  hero: msVideoPoster
}

const msImages = [
  msImage1,
  msImage2,
  msImage3,
  msImage4,
  msImage5,
  msImage6,
  msImage7,
  msImage8,
  msImage9,
  msImage10,
  msImage11,
  msImage12,
  msImage13,
  msImage14,
  msImage15,
  msImage16,
  msImage17,
  msImage18,
  msImage19,
  msImage20,
  msImage21,
  msImage22,
  msImage23,
  msImage24,
  msImage25,
  msImage26,
  msImage27,
  msImage28,
  msImage29,
  msImage30,
  msImage31,
  msImage32,
  msImage33,
  msImage34,
  msImage35,
  msImage36,
  msImage37,
  msImage38,
  msImage39,
  msImage40,
  msImage41,
  msImage42,
  msImage43,
  msImage44,
  msImage45,
  msImage46,
  msImage47,
  msVideo1
]

const msTitle = "Making Space";
const msSubtitle1 = "Photographic Traces of Absence, Stillness and the In-Between in Public Spaces";
const msSubtitle2 = "by Nicole Clare Fraser";

const msParagraph1 = "My studio practice explores banal and everyday public and institutional spaces, non-places, liminal spaces and ordinary inanimate objects that are experienced as a state of emptiness. My photographic project is made up of a series of images captured in and of built structures. Notions pertaining to time, space, non-place and the in-between are relevant to the reading of the work, and I elaborate on ideas about the perception of time and space. A photographic image is always a pause in time, the momentary freezing of an isolated instant, and as the maker of the image, I am inseparable from this pause. As the person who was there when the image was captured, the images inherently refer to my relationship to a particular place. Although I am physically absent from the image, my presence is felt through my way of seeing. This echoes the emptiness of the images, which are absent of the human form but are evident of human presence and usage. As this work was captured during a very unique historical circumstance, I reference the shifting experience of space in light of fears and concerns about contamination, infection and anxiety. Similarly, our understanding of touch has changed dramatically as a result of the Covid-19 pandemic. Every time we touch a surface or observe physical touch between people now, it is tainted by insecurity about spreading infection and illness. The notion of touch is echoed in the haptic process of working in the darkroom, physically connecting with the materiality of the printed page and with the tactile, touchable and vegetative objects within the images";
const msParagraph2 = "Our current moment is perpetually fraught with social, political and economic disparity and the inequality and conflict this manifests. Considering the current Covid-19 Pandemic experienced globally, these disparities have become even more evident. Shared spaces are now imbued with anxiety relating to the notion of infection. Therefore, due to restrictions on movement and access many spaces have been consequently closed and inaccessible. This has drastically shifted human experience of being in the world and this experience in South Africa, specifically, is further divided according to one’s economic, racial or gendered existence. The spaces captured within my project reflect my position within South Africa. They are maintained, they represent economic stability, they are largely interior spaces because as a female this is where I feel safer. The exterior city, outside of the confines of an enclosed building that I access through pre-arranged appointments, is volatile and imbued with uncertainty. As a female lensbased artist I seek spaces that enable a sense of control. Being perpetually aware of my gendered experience of space and an insecurity around personal security in the world influences and dictates my interaction within the built environment. By capturing liminal spaces through the language of deadpan photography this body of work aims to communicate notions relating to self, existence, personal experience and the lived interactions with the public and private built environment.";
const msParagraph3 = "What is interesting to me, is how structures and inanimate objects symbolise the designated function of a space; its particular use and activity. Through inanimate objects, written signage and semiotics we can deduce what activity is performed within a particular space. However, these signs written into the environment and onto inanimate objects are Making Space: Photographic Traces of Absence, Stillness and the In-Between in Public Spaces 2020 - 2021 understood according to personal experience. Space also communicates narratives around privilege and comfort, poverty and inequality and a place’s social, economic and political context and history. Emmanual Iduma is his essay, Even Walls, describes how “Walls, after they are built, do not last forever [...] walls interact frequently with human bodies, which are enclosed within or rest against them. Experience, as a result, accumulates on the surface of walls” (Baumann, Chuang & Onabanjo, 2017: 261).";
const msParagraph4 = "Through the documentation of spaces the human trace is always evident. This can be seen through the arrangement of inanimate objects used to facilitate the human body and the marks or stains we leave behind through repetitive usage. What is left behind also communicates the care and attention paid to a particular place. Within each of these spaces and the fabric of their makeup, so many details can be observed. There is information in the details and the seemingly insignificant.";
const msParagraph5 = "I have been capturing images of spaces for the last 10 years. The images are usually found through happenstance and/or sought for their particularity or specificity relating to aesthetic and function. The spaces are predominantly for public and recreational usage. I am interested in human relationships to familiar objects. Particular functional objects are prevalent within the images I select to capture; for example, seating, chairs, hand-rails, pot plants, ladders, tables and television screens.";
const msParagraph6 = "As human beings that occupy physical bodies, our lives are spent navigating and existing within built structures and facilitated by objects. The majority of our contemporary lived experience and everyday interactions occur within and are facilitated by the built environment. Therefore, these structures tell and hold stories about how we exist within the world. The still and quiet architectural interiors create a space for thoughtfulness, contemplation and intimate observation. This moment of quiet offers a space for the viewer to go inward, and feel, without reading visual clues written on photographed faces, expressions and overt narrative.";
const msParagraph7 = "I would like to draw attention to the notion of stillness and photography, and how through stillness images can quietly convey meaning. David Goldblatt reflects on his practice stating “It was the quiet and commonplace where nothing ‘happened’ and yet all was contained and immanent that I was most drawn.” (Goldblatt, 1998: 7). Through the process of selecting a scene to photograph, I am inherently predisposed to my personal experience of being in the world. As a reserved person who has difficulty with human engagement, social interaction is daunting to me. The slowing down of my photographic process through analogue photography and the careful image construction of the deadpan aesthetic enables me to stand at a distance and observe. My context and history dictates my perception of what surrounds me. This experience of place recorded through a camera is an exploration and personal questioning of existence.";
const msParagraph8 = "In choosing to put time into noticing a particularity within the banal and mundane and further committing it to the photographic negative one divulges what is important and worth remembering and collecting for the imagemaker. The collecting of public spaces where connectivity, socialisation and engagement occurs conflicts with my introverted disposition. This contradiction is made visible through documenting the spaces devoid of those that they are created for, without people and activity.";
const msParagraph9 = "My personal practice involves documenting built structures with a medium format analogue camera, using black and white film. Working with an analogue film camera in black and white enables me to spend time with the images I capture in the darkroom. I am drawn to the quiet of this space and the haptic process of hand printing. The process feels ceremonial, meditative and precious. The process is predictable and determined by following careful instruction and method. This control gives me a sense of calm and order amidst the chaos and confusion of everyday life. The process of hand printing enables me to spend time with the images. There is a meditative quality to the printing of the images. The images, which are still, frozen in time, are carefully printed through the practice of spending time alone, in a dimly illuminated room, where time determines outcome.";
const msParagraph10 = "References";
const msParagraph11 = "Baumann, D., Chuang J. & Onabanjo, O.C. 2017. Recent Histories : Contemporary African Photography and Video Art . New York: The Walther Collection";
const msParagraph12 = "Goldblatt, D. 1998. South Africa: the Structure of Things Then. Cape Town: Oxford University Press";

const msBody = [msParagraph1, msParagraph2, msParagraph3, msParagraph4, msParagraph5, msParagraph6, msParagraph7, msParagraph8, msParagraph9, msParagraph10, msParagraph11, msParagraph12];

const ms = {
  images: msImages, 
  title: msTitle,
  subtitle1: msSubtitle1,
  subtitle2: msSubtitle2,
  body: msBody
}

export const random = [
  aqp15,
  aqp16
]

export const exhibitions = {
  aqp: aqp,
  uf: uf,
  ms: ms,
}