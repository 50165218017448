import React from 'react';
import './MobileMenu.css';

const MobileMenu = (props) => {
  return (
    <div className="MobileMenu">
      <p onClick={props.revealMS}>making space</p>
      <p onClick={props.revealUntitled}>untitled (façade)</p>
      <p onClick={props.revealAQuietPlace}>a quiet place</p>
    </div>
  );
};

export default MobileMenu;
