import React from 'react';
import './Gallery.css';
import { random } from '../../Media/Media';

const Gallery = () => {
  const returnImage = () => {
    return random[Math.round(Math.random() * 1)];
  };

  return (
    <div className="Gallery">
      <div>
        <img src={returnImage()} alt="A Quiet Place 1" />
      </div>
    </div>
  );
};

export default Gallery;
