import { useEffect, useState } from 'react';
import { Player } from 'video-react';
import './Exhibition.css';
import { exhibitions } from '../../Media/Media';

const Exhibition = (props) => {
  const [exhibition, setExhibition] = useState({});

  console.log(props.exhibition);

  useEffect(() => {
    switch (props.exhibition) {
      case 'aqp':
        setExhibition(exhibitions.aqp);
        break;
      case 'uf':
        setExhibition(exhibitions.uf);
        break;
      case 'ms':
        setExhibition(exhibitions.ms);
        break;
      default:
        setExhibition(exhibitions.aqp);
        break;
    }
  }, [props.exhibition]);

  return (
    <>{exhibition.body &&
      <div className="Exhibition">
        <div className="text">
          <p className="h1">{exhibition.title}</p>
          <p className="h4">{exhibition.subtitle1}</p>
          {exhibition.body.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
        <div className="images" id="images">
          {exhibition.images.map((image, index) => (
            <div className="imageContainer" key={index}>
              {image.image && <img src={image.image} alt="Untitled" loading="lazy" />}
              {image.video && <Player
                                playsInline
                                poster={image.hero}
                                src={image.video}
                                className="video"
                              />}
              <div className="imageInfoContainer">
                {image.title && <p><em>{image.title}</em></p>}
                {image.year && <p>{image.year}</p>}
                {image.method && <p>{image.method}</p>}
                {image.dimension && <p>{image.dimension}</p>}
                {image.paperSize && <p>{image.paperSize}</p>}
                {image.framed && <p>{image.framed}</p>}
                {image.edition && <p>{image.edition}</p>}
              </div>
            </div>
          ))}
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>}
    </>
  );
};

export default Exhibition;
