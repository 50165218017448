import React from 'react';
import './InfoBar.css';

const InfoBar = (props) => {
  const displayInfoBar = () => {
    if (props.aboutShown) {
      return (
        <div>
          <div className="InfoBar">
            <span onClick={props.revealGallery}><p id="long">Nicole Clare Fraser</p><p id="short">NCF</p></span>
            <p onClick={props.revealAbout} className="underlined">About</p>
          </div>
          <div id="top">
            <p onClick={props.revealMobileMenu}>&#9776;</p>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="InfoBar">
            <span onClick={props.revealGallery}><p id="long">Nicole Clare Fraser</p><p id="short">NCF</p></span>
            <p onClick={props.revealAbout}>About</p>
          </div>
          <div id="top">
            <p onClick={props.revealMobileMenu}>&#9776;</p>
          </div>
        </div>
      );
    }
  };

  return displayInfoBar();
};

export default InfoBar;
