import { useState } from 'react';
import Menu from './Components/Menu/Menu';
import InfoBar from './Components/InfoBar/InfoBar';
import Gallery from './Components/Gallery/Gallery';
import About from './Components/About/About';
import MobileMenu from './Components/MobileMenu/MobileMenu';
import Exhibition from './Components/Exhibition/Exhibition';
import './App.css';

const App = () => {
  const [galleryShown, setGalleryShown] = useState(true);
  const [aboutShown, setAboutShown] = useState(false);
  const [exhibition, setExhibition] = useState(''); 
  const [mobileMenu, setMobileMenu] = useState(false);

  const revealGallery = () => {
    window.scrollTo(0, 0);
    setGalleryShown(true);
    setAboutShown(false);
    setMobileMenu(false);
    setExhibition('');
  };

  const revealAbout = () => {
    window.scrollTo(0, 0);
    setGalleryShown(false);
    setAboutShown(true);
    setMobileMenu(false);
    setExhibition('');
  };

  const revealAQuietPlace = () => {
    window.scrollTo(0, 0);
    setGalleryShown(false);
    setAboutShown(false);
    setMobileMenu(false);
    setExhibition('aqp');
  };

  const revealUntitled = () => {
    window.scrollTo(0, 0);
    setGalleryShown(false);
    setAboutShown(false);
    setMobileMenu(false);
    setExhibition('uf');
  };

  const revealMS = () => {
    window.scrollTo(0, 0);
    setGalleryShown(false);
    setAboutShown(false);
    setMobileMenu(false);
    setExhibition('ms');
  };

  const revealMobileMenu = () => {
    window.scrollTo(0, 0);
    setGalleryShown(mobileMenu);
    setAboutShown(false);
    setMobileMenu(!mobileMenu);
    setExhibition('');
  };

  const renderBody = () => {
    if (galleryShown) {
      return <Gallery />;
    } else if (aboutShown) {
      return <About revealGallery={revealGallery} />;
    } else if (exhibition !== '') {
      return <Exhibition exhibition={exhibition}/>;
    } else if (mobileMenu) {
      return <MobileMenu revealGallery={revealGallery} revealAQuietPlace={revealAQuietPlace} revealUntitled={revealUntitled} revealMS={revealMS}/>;
    }
  };

  const renderMenu = () => {
    if (aboutShown) {
      return null;
    } else {
      return <Menu revealAQuietPlace={revealAQuietPlace} revealUntitled={revealUntitled} revealMobileMenu={revealMobileMenu} revealMS={revealMS} selected={exhibition} />;
    }
  };

  return (
    <div className="App">
      {renderMenu()}
      {renderBody()}
      <InfoBar revealAbout={revealAbout} revealGallery={revealGallery} revealMobileMenu={revealMobileMenu} aboutShown={aboutShown} />
    </div>
  );
};

export default App;
