import React from 'react';
import './Menu.css';

const Menu = (props) => {
  return (
    <div className="MenuBar">
      <div className="side">
        <p onClick={props.revealMS} className={props.selected === "ms" ? "underlined" : ""}>making space</p>
        <p onClick={props.revealUntitled} className={props.selected === "uf" ? "underlined" : ""}>untitled (façade)</p>
        <p onClick={props.revealAQuietPlace} className={props.selected === "aqp" ? "underlined" : ""}>a quiet place</p>
      </div>
    </div>
  );
};

export default Menu;
